import { defineComponent, ref } from 'vue';
import FormToggleSwitch from '@/components/layout/form/toggle-switch/FormToggleSwitch.vue';
export default defineComponent({
    name: 'NicknameToggler',
    components: { FormToggleSwitch },
    props: {
        label: {
            type: String,
            required: true
        }
    },
    emits: ['filter-by-players'],
    setup(props, context) {
        const isFilteredByPlayer = ref(false);
        function filterByPlayer(isChecked) {
            isFilteredByPlayer.value = isChecked;
            context.emit('filter-by-players', isChecked);
        }
        return { isFilteredByPlayer, filterByPlayer };
    }
});
