import { defineComponent } from 'vue';
import HeaderLink from '@/components/layout/header/header-desktop/HeaderDesktopLink.vue';
import HeaderLogoImage from '@/components/layout/header/header-desktop/HeaderLogoImage.vue';
export default defineComponent({
    name: 'AdminMenu',
    components: {
        HeaderLogoImage,
        HeaderLink,
    },
    props: {
        adminSide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        return {};
    },
});
