import { API_URL, deleteRequest, getRequest, postRequest, putRequest, } from '@/assets/ts/helpers/api';
const getNicknames = (filteringData) => postRequest(`${API_URL}/nicknames`, filteringData);
const addNewNicknamesRequest = (profile_id) => postRequest(`${API_URL}/nicknames/request`, { profile_id });
function getNicknamesRequests() {
    return getRequest(`${API_URL}/admin/nicknames/pending-requests`, true);
}
function acceptNicknamesRequest(requestId, nicknames, adminId) {
    return putRequest(`${API_URL}/admin/nicknames/requests/consider`, {
        assignment_request_id: requestId,
        accept: true,
        nicknames,
        admin_id: adminId
    }, true);
}
function declineNicknamesRequest(requestId, adminId) {
    return putRequest(`${API_URL}/admin/nicknames/requests/consider`, { assignment_request_id: requestId, accept: false, admin_id: adminId }, true);
}
const getNicknamesAdmin = () => getRequest(`${API_URL}/admin/nicknames`, true);
const addNewNicknameRequest = (nickname) => postRequest(`${API_URL}/admin/nicknames`, nickname, true);
const deleteNicknameRequest = (nicknameId) => deleteRequest(`${API_URL}/admin/nicknames/${nicknameId}`, true);
function updateNicknameRequest(nicknameId, nickname) {
    return putRequest(`${API_URL}/admin/nicknames/${nicknameId}`, nickname, true);
}
export { getNicknames, addNewNicknamesRequest, getNicknamesRequests, acceptNicknamesRequest, declineNicknamesRequest, getNicknamesAdmin, addNewNicknameRequest, deleteNicknameRequest, updateNicknameRequest, };
