<template>
  <button class="btn btn-cta"
          :class="{'btn-cta--green': theme === 'green', 'btn-cta--red': theme === 'red', 'btn-cta--info': theme === 'info'}"
          :disabled="disabled"
          @click="emitClick">
    <slot name="icon"/>
    <span v-if="text">{{ text }}</span>
  </button>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CTAButton',
  props: {
    text: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    theme: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: [
    'on-click'
  ],
  setup(props, context) {
    const emitClick = ($event) => context.emit('on-click', $event);

    return {emitClick};
  }
})
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.btn-cta {
  background-color: $color-yellow;
  color: $color-darkest;
  font-size: 1rem;
  line-height: 1.125rem;
  border: none;

  &--green {
    background-color: $color-green;
    color: $color-white;
  }

  &--red {
    background-color: $color-red;
    color: $color-white;
  }

  &--info {
    background-color: $color-aqua;
    color: $color-white;
  }
}
</style>
