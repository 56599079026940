import { defineComponent, ref } from 'vue';
import Modal from '@/components/layout/modal/Modal.vue';
import { addNewNicknamesRequest } from '@/assets/ts/api/nickname';
export default defineComponent({
    components: {
        Modal,
    },
    setup() {
        const addNicknamesModalRef = ref();
        const msgModalRef = ref();
        const textMsg = ref('');
        const headerMsg = ref('');
        const profileToChangeId = ref('');
        const toggleModal = () => addNicknamesModalRef.value.toggleModal();
        const execute = () => {
            addNewNicknamesRequest(profileToChangeId.value).then(() => {
                toggleModal();
                textMsg.value = `Pomyślnie zgłoszono prośbę o przypisanie nicków dla profilu o ID: ${profileToChangeId.value}!`;
                headerMsg.value = 'Zgłoszenie wysłano';
                profileToChangeId.value = '';
            }).catch((errorMessage) => {
                toggleModal();
                textMsg.value = errorMessage;
                headerMsg.value = 'Błąd';
            }).finally(() => msgModalRef.value.toggleModal());
        };
        const cancel = () => {
            toggleModal();
            profileToChangeId.value = '';
        };
        return {
            addNicknamesModalRef,
            msgModalRef,
            textMsg,
            headerMsg,
            profileToChangeId,
            toggleModal,
            execute,
            cancel,
        };
    },
});
