import { defineComponent, onMounted, ref } from 'vue';
import HeaderMobile from '@/components/layout/header/header-mobile/HeaderMobile.vue';
import HeaderDesktop from '@/components/layout/header/header-desktop/HeaderDesktop.vue';
import AdminMenu from '@/components/layout/admin/AdminMenu.vue';
import Footer from '@/components/layout/footer/footer-mobile/FooterMobile.vue';
import { getLoggedAdmin } from '@/assets/ts/helpers/admin';
export default defineComponent({
    name: 'Subpage',
    components: {
        HeaderMobile,
        HeaderDesktop,
        AdminMenu,
        Footer
    },
    props: {
        adminSide: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        const headerRef = ref();
        const loggedAdmin = ref(null);
        onMounted(() => loggedAdmin.value = getLoggedAdmin());
        function updateAdminInfo() {
            loggedAdmin.value = getLoggedAdmin();
            if (headerRef.value) {
                headerRef.value.updateAdminInfo();
            }
        }
        return { loggedAdmin, headerRef, updateAdminInfo };
    }
});
