import { defineComponent, ref } from 'vue';
import AddNicknamesModal from '../../modal/add-nicknames-modal/AddNicknamesModal.vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    name: 'RightAside',
    components: {
        CTAButton,
        AddNicknamesModal,
    },
    props: {
        isFiltered: {
            type: Boolean,
            required: false,
            default: false,
        },
        addNewNick: {
            type: String,
            required: false,
            default: null,
        },
        noResultsTitle: {
            type: String,
            required: false,
            default: '',
        },
        noResultsSubtitle: {
            type: String,
            required: false,
            default: '',
        },
        noResults: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const addNicknamesModalRef = ref(null);
        function openModal() {
            addNicknamesModalRef.value.toggleModal();
        }
        return { addNicknamesModalRef, openModal };
    },
});
