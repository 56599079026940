import { computed, defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'TextFilter',
    props: {
        title: {
            required: true,
            type: String,
            default: ''
        },
        description: {
            required: false,
            type: String,
            default: ''
        },
        isFirst: {
            required: false,
            type: Boolean,
            default: false
        },
        placeholder: {
            required: false,
            type: String,
            default: ''
        },
        passwordType: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change'
    ],
    setup(props) {
        const inputData = ref('');
        const inputId = computed(() => {
            return props.title.toLowerCase().replace(' ', '-');
        });
        function resetData() {
            inputData.value = '';
        }
        function getInputValue() {
            return inputData.value;
        }
        return { inputData, inputId, getInputValue, resetData };
    }
});
