import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
    name: 'FormToggleSwitch',
    props: {
        index: {
            type: Number,
            required: false,
            default: 0
        },
        startValue: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['toggle'],
    setup(props, context) {
        const isChecked = ref(false);
        onMounted(() => {
            isChecked.value = props.startValue;
        });
        function emitChangeEvent() {
            context.emit('toggle', isChecked.value);
        }
        function restoreValue() {
            isChecked.value = !isChecked.value;
        }
        function getValue() {
            return isChecked.value;
        }
        return { isChecked, getValue, restoreValue, emitChangeEvent };
    }
});
